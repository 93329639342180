import React from "react"
import {PageProps} from "gatsby"
import dayjs from "dayjs"
import "dayjs/locale/nl"

import {Page} from "../../types"

import Layout from "../components/layout"
import PageWidth from "../components/page-width"
import BannerGroup from "../components/banner-group"
import NewsBlock from "../components/blocks/news-block"

const NewsLanding = (page: PageProps): JSX.Element => {
  const data = page.pageContext as Page
  dayjs.locale("nl")

  return (
    <Layout title={data.title} currentPage={data}>
      <BannerGroup banners={data.header} />
      <NewsBlock />
      <PageWidth>
        <div dangerouslySetInnerHTML={{__html: data.content}} />
      </PageWidth>
    </Layout>
  )
}

export default NewsLanding
